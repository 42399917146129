export const firebaseConfig = {
    apiKey: "AIzaSyBjeX7pVLgyaB7RtU2SuqrIbhEg7dJCPeQ",
    authDomain: "whereispaulie.com",
    databaseURL: "https://mypaulie-000.firebaseio.com",
    projectId: "mypaulie-000",
    storageBucket: "mypaulie-000.appspot.com",
    messagingSenderId: "42655989043",
    appId: "1:42655989043:web:b502fcf04d22e7dcc19246",
    measurementId: "G-C24TT6VPJJ"
};
